@import 'styles/all_vars';
//@import '~flag-icon-css/css/flag-icon.css';

$bookContentBorder: 2px solid $grey;
$bookContentPadding: 2rem;
$bookContentPaddingMobile: 1.3rem;

.book-menus {
  width: 100%;
}
.book-menus__wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}

.book-menu__space {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-weight: $weight-normal;
}

.book-menu__view {
  font-size: $size-small;
  color: $red-dark;
  cursor: pointer;
}

.book-summary__menus {
  min-height: 2rem;
}

.book-summary__menus__menu {
  display: block;
}

.book-summary {
  text-transform: uppercase;
  line-height: 1.5;

  #payment_total .amount {
    color: $red;
  }

  #menus .amount del {
    margin-right: 5px;
  }
}

.checkout__content__summary {
  @include mobile() {
    margin-right: 1rem;
    margin-left: 1rem;
    border-radius: $radius-large;
  }
}

.checkout__footer {
  @include mobile() {
    margin-bottom: 5rem;
  }
}

.step-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  z-index: 10;
}

.step-loading__content {
  height: 100%;
}

.eb-phone-input {
  select {
    opacity: 0;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .select {
    background-color: $grey-light;
    border-top: 2px solid $grey;
    border-bottom: 2px solid $grey;
    border-left: 2px solid $grey;

    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    //border-radius-topleft: 10px;
  }

  &__label {
    padding-left: 0.8rem;
  }

  &__flag {
    height: $control-height;
    font-size: $size-normal;
    font-weight: $weight-normal;
    justify-content: flex-start;
    display: inline-flex;
    line-height: 2;
    padding: 0.5rem 2.7rem 0.5rem 0.5rem;

    .flag-icon {
      border-radius: $radius-rounded;
      width: 1.86667rem;
      height: 1.86667rem;
    }
  }
}

.step-fields {
  max-width: 600px;
  margin: 0 auto;

  label[for='is_subscribe'] {
    line-height: 1.2;
    display: flex;
  }
}

.is-giftcode-tag {
  color: $blue;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }

  .tag {
    background-color: $grey-2;
  }
}

.eb-booking-terms {
  padding: 2rem;

  a {
    border-bottom: 1px solid $red;
  }
}

.checkout__menus {
  padding-left: 1.5rem !important;
}
